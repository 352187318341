




























































import useAbstract from "@/use/abstract";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAbstractsList: () =>
      import(
        "@/components/organisms/event/abstractModule/o-abstracts-list.vue"
      ),
  },

  setup(_, { root }) {
    const { statusItems, typeItems } = useAbstract({ root });
    const state = reactive({
      title: "",
      author: "",
      status: undefined,
      type: undefined,
    });

    const statusElements = [
      { value: "", name: root?.$tc("layout.misc.all") },
      ...statusItems,
    ];
    const typeElements = [
      { value: "", label: root?.$tc("layout.misc.all") },
      ...typeItems,
    ];

    return { state, statusElements, typeElements };
  },
});
